import React from 'react';

import Brand from './src/client/models/Brand';
import Layout from './src/client/components/Layout';
import Site from './src/client/models/Site';

export { default as wrapRootElement } from './src/client/state/redux-wrapper';

export const onClientEntry = () => {
  const headExternals = [
    `https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_SITE_GTAG_WPROMOTE}`,
    `https://cdn-widgetsrepository.yotpo.com/v1/loader/${process.env.GATSBY_SITE_YOTPO_GUID}`,
    `https://staticw2.yotpo.com/${process.env.GATSBY_SITE_YOTPO_APP_KEY}/widget.js`,
    `https://cdn-loyalty.yotpo.com/loader/${process.env.GATSBY_SITE_YOTPO_GUID}.js`,
  ];

  const footerExternals = [
    `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.GATSBY_SITE_KLAVIYO_COMPANY_ID}`,
  ];

  const loadExternal = (location, url) => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    if (location === 'head') {
      document.head.prepend(script);
    } else {
      document.body.appendChild(script);
    }
  };

  window.onload = () => {
    headExternals.forEach((external) => loadExternal('head', external));
    footerExternals.forEach((external) => loadExternal('footer', external));

    window.dataLayer = window.dataLayer || [];
    function gtag_wpromote() {
      dataLayer.push(arguments);
    }
    gtag_wpromote('js', new Date());
    gtag_wpromote('config', process.env.GATSBY_SITE_GTAG_WPROMOTE);
    window.gtag_wpromote = gtag_wpromote;
  };
};

export const wrapPageElement = ({ element, props }) => {
  const unique = [
    '_id',
    'countryCode',
    'currencyCode',
    'currencySymbol',
    'currencySymbolPlacement',
    'enabled',
    'id',
    'includeTaxes',
    'inventoryField',
    'originalPriceField',
    'quantitySoldField',
    'sellPriceField',
    'shopEnabled',
    'strings',
    'type',
    'uid',
  ];

  const { siteUID } = props.pageContext;

  if (!siteUID) return React.cloneElement(element, props);

  const brand = new Brand(props.pageContext.brand);

  const baseSites = props.pageContext.sites;

  for (const mirrorSite of props.pageContext.mirrorSites) {
    const originalSite = props.pageContext.sites.find(
      (site) => site.node._id === mirrorSite.node.mirrorSite._id
    )?.node;

    const baseSite = {
      node: {
        ...originalSite,
        ...unique.reduce(
          (acc, field) => ({
            ...acc,
            [field]: mirrorSite.node[field],
          }),
          {}
        ),
        mirrorSite: {
          uid: {
            current: originalSite.uid.current,
          },
        },
      },
    };

    if (!baseSites.find((site) => site.node._id === baseSite.node._id)) {
      baseSites.push(baseSite);
    }
  }
  const sites = baseSites.map((site) => new Site(site.node));
  const site = sites.find(
    (site) => site.brandUID === brand.brandUID && site.siteUID === siteUID
  );

  if (siteUID === '') {
    return React.cloneElement(element, { brand, site, sites });
  }

  return (
    <Layout
      brand={brand}
      site={site}
      sites={sites}
      siteUID={siteUID}
      {...props}
    >
      {React.cloneElement(element, { brand, site })}
    </Layout>
  );
};
